// author:马云阳；description:众惠-基金定投
<template>
  <section class="publicfund">
    <!-- v-if="showpg" -->
    <div  class="publicfund_cont">
      <div class="title_">
        <div class="left_ti">
          基金定投
        </div>
        <a @click="tonewfund" class="right_btn">新定投</a>
      </div>
      <div class="card_list">
        <div class="cars_">
          <img src="@/static/img/my/du_type.png" alt="" class="left_img">
          <div class="cont_">
            <div class="top_txt">已坚持定投</div>
            <div class="num_">
              <span class="num_txt">{{setfunddays.days||'--'}}</span>
              <span class="num_ti">天</span>
            </div>
          </div>
        </div>
        <div class="cars_">
          <img src="@/static/img/my/du_Mony.png" alt="" class="left_img">
          <div class="cont_">
            <div class="top_txt">月定投</div>
            <div class="num_">
              <span class="num_txt">{{setfunddays.allBalance||'--'}}</span>
              <span class="num_ti">元</span>
            </div>
          </div>
        </div>
        <div class="cars_">
          <img src="@/static/img/my/dt_num.png" alt="" class="left_img">
          <div class="cont_">
            <div class="top_txt">定投数量</div>
            <div class="num_">
              <span class="num_txt">{{setfunddays.reqCount||'--'}}</span>
              <span class="num_ti">只</span>
            </div>
          </div>
        </div>
      </div>

      <div class="tables_cont">
        <div class="screen">
          <a v-for="(btn,i) in screen" :key="i" @click="screens(i)" :class="screenindex===i?'btn_ac':''"
            class="screen_btn">{{btn}}</a>
        </div>
        <div v-if="setdatalist&&setdatalist.length>0" class="table_">
          <!-- v-for="(obj,i) in trs" :key="i"  -->
          <div class="tr_ti">
            <div class="ti_1">基金简称 </div>
            <div class="ti_2">每期定投(元) </div>
            <div class="ti_3">累计定投(元) </div>
            <div class="ti_4">定投状态 </div>
            <div class="ti_5">下次扣款 </div>
            <div class="ti_6">已定投期数 </div>
            <div class="ti_7">操作 </div>
          </div>
          <!--  -->
          <div v-for="(item,j) in setdatalist" :key="j" :class="(setdatalist&&setdatalist.length==(j+1))?'boders_add':''" class="td">
            <div class="td_s">
              <div :class="item.fixState=='A'&&item.status=='1'?'':'procolor'" class="td_1">{{item.fundName}}</div>
              <div v-if="showindexs==j&&showmodels" class="show_models">{{item.fundName}}
              </div>
            </div>
            <div :class="item.fixState=='A'&&item.status=='1'?'':'procolor'" class="td_2">{{item.balance}}</div>
            <div :class="item.fixState=='A'&&item.status=='1'?'':'procolor'" class="td_3">{{item.totalBalance||'--'}}</div>
            <div :class="item.fixState=='A'&&item.status=='1'?'':'procolor'" class="td_4">
              {{item.status=='1'?(item.fixState=='A'?'正常':item.fixState=='P'?'已暂停':item.fixState=='H'?'已终止':'正常'):
              item.status=='0'?"初始":
              item.status=='2'?"失败":
              item.status=='3'?"异常":
              item.status=='4'?"变更中":"--"
              }}
              </div>
            <div :class="item.nextFixrequestDate?'profitac':''" class="td_5">{{item.nextFixrequestDate||'--'}}</div>
            <div class="td_6">{{item.totalUnit}}</div>
            <div class="td_7"><a @click="item.status!='1'&&item.status!='3'?'':topageId(item)" :class="item.status!='1'&&item.status!='3'?'procolor':''" class="td_7">详情</a></div>
            

          </div>
          <div class="par_foot">
                    <pagination
                      v-show="total > 0"
                      :total="total"
                      :page.sync="page"
                      :limit.sync="pageSize"
                      :layout="layout"
                      @pagination="goPage"
                    />
            </div>
        </div>
        <div v-else class="null_tab">
          <img src="@/static/img/my/null_bg.png" alt="">
          <div class="null_txt">暂无相关定投记录</div>
        </div>
      </div>
      
    </div>
	<!-- <router-view /> -->
    
  </section>
</template>
<script>
import { fixallotPage, statisticFixallot } from "@/api/mysetcast";

export default {
  name: "FundSetFing",
  components: {},
  data() {
    return {
      trs: {
        title1: "基金",
        title2: "昨日收益",
        title3: "累计收益",
        title4: "累计收益率",
        title5: "操作"
      },
      setdatalist: [],
      showindexs: null,
      showmodels: false,
      screen: ["全部", "正常", "已暂停"],
      screenindex: 0, //默认筛选全部
      showpg: true,
      page: 1,
      pageSize: 10,
      total: 0,
      layout: "prev, pager, next,sizes",
      setfunddays: {} //我的基金定投数据
    };
  },
  methods: {
    //查询定投基金参数
    getsetfund() {
      statisticFixallot().then(res => {
        if (res.code != 200) {
          this.$message.error("当前请求失败，请稍后再试！");
        } else {
          this.setfunddays = res.data || {};
          this.getsetpage({ fixState: null ,pageNum:this.page,pageSize:this.pageSize});
        }
      });
    },
    goPage(page) {
      this.page = page.page;
      this.pageSize = page.limit;
      if (this.screenindex == 0) {
        this.getsetpage({ fixState: null ,pageNum:this.page,pageSize:this.pageSize});
      } else if (this.screenindex == 1) {
        this.getsetpage({ fixState: "A", status: 1 ,pageNum:this.page,pageSize:this.pageSize});
      } else {
        this.getsetpage({ fixState: "P", status: 1 ,pageNum:this.page,pageSize:this.pageSize});
      }
    },
    tonewfund() {
      this.$router.push({ name: "Fixedarea" });
    },
    // 查询我的基金定投
    getsetpage(params) {
      fixallotPage(params).then(res => {
        if (res.code != 200) {
          this.$message.error("当前请求失败，请稍后再试！");
        } else {
          this.setdatalist = res.data.data;
          this.page=res.data.pageNum;
          this.total=res.data.total;
          this.pageSize=res.data.pageSize
        }
      });
    },
    move(j) {
      this.showmodels = true;
      this.showindexs = j;
    },
    moveout() {
      this.showmodels = false;
      this.showindexs = null;
    },

    screens(i) {
      this.screenindex = i;
      this.page=1;
      if (i == 0) {
        this.getsetpage({ fixState: null ,pageNum:this.page,pageSize:this.pageSize});
      } else if (i == 1) {
        this.getsetpage({ fixState: "A", status: 1 ,pageNum:this.page,pageSize:this.pageSize});
      } else {
        this.getsetpage({ fixState: "P", status: 1 ,pageNum:this.page,pageSize:this.pageSize});
      }
    },
    topageId(obj) {
      let a = JSON.stringify(obj);

      this.$router.push({ name: "DinDetails", query: { a } });
      // this.showpg = false;
    }
  },
  mounted() {
    // this.showpg = true;
  },
  // watch: {
  //   $route(to, from) {
  //     console.log("", from);
  //     if (to.name == "FundSetFing") {
  //       this.showpg = true;
  //     } else {
  //       this.showpg = false;
  //     }
  //   }
  // },
  created() {
    this.getsetfund();
  }
};
</script>
<style lang="less" scoped>
.profitac {
  color: #e45247 !important;
}
.boders_add{
            border-bottom: 1px solid #f3f4f6!important;
}
.profit {
  color: #01b834 !important;
}

.procolor {
  color: #8691a8 !important;
}
.publicfund {
  min-height: 800px;
  // background-color: #f1f1f1;

  .publicfund_cont {
    padding: 40px 40px 70px 40px;
    background: #fff;
    // min-height: 6px;
    // width: 850px;

    .title_ {
      height: 60px;
      border-bottom: 1px solid #edf1f7;
      font-size: 20px;
      color: #1f1f1f;
      display: flex;
      // align-items: center;
      justify-content: space-between;

      .right_btn {
        display: inline-block;
        width: 146px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: linear-gradient(107deg, #f7d9b7 0%, #eabf96 100%);
        border-radius: 2px;
        font-size: 16px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
        font-weight: 400;
        color: #b88141;
      }
    }

    .card_list {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cars_ {
        width: 239px;
        height: 105px;
        padding: 0px 14px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 4px 12px 0px #edf1f7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_img {
          width: 65px;
          height: 65px;
        }

        .cont_ {
          width: 62%;

          .top_txt {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #a5a5a5;
          }

          .num_ {
            margin-top: 5px;

            .num_txt {
              display: inline-block;
              width: 128px;
              // height: ;
              font-size: 30px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #1f1f1f;
            }

            .num_ti {
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #a5a5a5;
            }
          }
        }
      }
    }

    .tables_cont {
      margin-top: 40px;

      .screen {
        display: flex;
        align-items: center;

        .screen_btn {
          width: 92px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #deb87b;
          margin-right: 14px;
        }

        .btn_ac {
          background: #deb87b;
          border-radius: 4px;
          color: #fff;
        }
      }

      .table_ {
        margin-top: 22px;

        .tr_ti {
          display: flex;
          align-items: center;

          div {
            height: 59px;
            font-size: 12px;
            color: #1f1f1f;
            background: #f3f4f6;
            line-height: 60px;
            border-top: 1px solid #f3f4f6;
            border-left: 1px solid #ffffff;
            text-indent: 2em;

            &:first-child {
              border-left: 1px solid #f3f4f6;
            }

            &:last-child {
              border-right: 1px solid #f3f4f6;
            }
          }

          .ti_1 {
            width: 199px;
          }
          .ti_4_1ac_ {
            width: 299px;
            position: relative;

            .td_1 {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .show_models {
              width: 200px;
              min-height: 30px !important;
              height: 0px;
              padding: 4px;
              text-indent: 0em !important;
              background: #fff;
              border: 1px solid #f1f1f1;
              position: absolute;
              left: 120px;
              top: 0px;
              z-index: 999;
              line-height: 20px !important;
              box-shadow: 0px 2px 4px 0px #edf1f7;
            }
          }

          .ti_5 {
            width: 225px;
          }

          .ti_2,
          .ti_3,
          .ti_5 {
            width: 119px;
          }

          .ti_4 {
            width: 89px;
          }

          .ti_6 {
            width: 79px;
            text-indent: 0em;
            text-align: center;
          }
          .ti_7 {
            width: 113px;
            text-indent: 0em;
            text-align: center;
          }
        }

        .td {
          display: flex;
          align-items: center;

          &:last-child {
            border-bottom: 1px solid #f3f4f6!important;
          }

          div {
            height: 59px;
            font-size: 12px;
            color: #1f1f1f;
            line-height: 60px;
            border-top: 1px solid #f3f4f6;
            border-left: 1px solid #f3f4f6;
            text-indent: 2em;

            &:first-child {
              border-left: 1px solid #f3f4f6;
            }

            &:last-child {
              border-right: 1px solid #f3f4f6;
            }
          }

          .td_s {
            width: 199px;
            position: relative;

            .td_1 {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .show_models {
              width: 200px;
              min-height: 30px !important;
              height: 0px;
              padding: 4px;
              text-indent: 0em !important;
              background: #fff;
              border: 1px solid #f1f1f1;
              position: absolute;
              left: 120px;
              top: 0px;
              z-index: 999;
              line-height: 20px !important;
              box-shadow: 0px 2px 4px 0px #edf1f7;
            }
          }

          .td_2,
          .td_3,
          .td_5 {
            width: 119px;
          }

          .td_4 {
            width: 89px;
          }

          .td_6 {
            width: 79px;
            text-indent: 0em;
            text-align: center;
          }
          .td_7 {
            width: 113px;
            text-indent: 0em;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #ce9b63;
          }
        }
      }
      .null_tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        img {
          width: 266px;
          height: 266px;
          margin-bottom: 20px;
        }
        .null_txt {
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #ce9b63;
        }
      }
    }
  }
}
</style>